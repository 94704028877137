.content_h1 {
    font-weight: bold;
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.content_h2 {
    font-weight: bold;
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.content_h3 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.content_h4 {
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.content_p {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
}

.content_p code {
    font-size: 12px;
    background-color: #ccc;
}

.content_p a {
    color: #ab1000;
}

.content_hr {
    border: 0;
    margin-bottom: 20px;
    text-align: center;
}

.content_hr::before {
    display: inline-block;
    content: '***';
    font-size: 30px;
    line-height: 65px;
    height: 30px;
    letter-spacing: 0.2em;
}

.content_figure img {
    width: 100%;
}

.content_quote {
    margin: 10px 20px;
}

.content_quote blockquote {
    text-indent: -0.45em;
    margin: 10px;
    font-style: italic;
}

.content_quote figcaption {
    margin-left: 10px;
}

.content_quote blockquote::before {
    content: open-quote;
}

.content_quote blockquote::after {
    content: close-quote;
}

blockquote {
    quotes: '“' '”' '‘' '’';
}
