body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scenecontainer {
    /* height:width ratio = 10/16 = .625  */
    /* ratio = 10/16 * 90 = 56.25 */
    height: calc(62.25vw - 0px);

    /* 95% of viewport width */
    width: 100vw;

    max-height: calc(100vh - 0px);
    max-width: 160vh;

    /* other settings */
    background: #555050;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    overflow: hidden;

    box-shadow: #00000030 0px 20px 30px;
}

.scene {
    position: absolute;
    left: 0;
    top: 0;
    width: 1920px;
    height: 1200px;
}

.scenecontainerInner {
    transform-origin: top left;
    transform: scale(100%);
}

.mainNavigation {
    position: absolute;
    z-index: 200;
    right: 0;
    top: 0;
}

.overlayBg {
    z-index: 199;
    background: #0000004d;
    background: radial-gradient(circle at top right, #00000020, #00000080);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.mainNavigation .iconContainer {
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: #ffffffc0;
}

.mainNavigation .drawer {
    position: absolute;
    right: 0px;
    width: 180px;
}

.mainNavigation .drawer ul {
    padding: 0;
    margin: 0;
}
.mainNavigation .drawer li {
    list-style: none;
    background-color: #fffffff8;
    margin-top: 2px;
    padding: 5px;
    vertical-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: background-color 200ms;
    cursor: pointer;
}

.mainNavigation .drawer li:hover {
    background-color: #ffffffe8;
}

.mainNavigation .drawer svg {
    margin-right: 16px;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
