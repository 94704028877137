#root {
    height: 100vh;
    background-color: #3c3c3c;
}
.bonsai {
    position: relative;
    height: 100%;
    background-image: url('../../public/assets/images/dashboard-bg.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.startPage {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.startPage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../public/assets/images/startscreen.jpg');
    background-size: cover;
    background-position: center;
}

.startPage {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.startPage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../public/assets/images/startscreen.jpg');
    background-size: cover;
    background-position: center;
}

.startMenu {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 15%;
    right: 10%;
    align-self: flex-start;
    opacity: 0;
    animation: fade-in 1s ease forwards;
    height: 80vh;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgba(136, 136, 136, 0.5) rgba(245, 245, 245, 0.5);
}

.startMenuIconButton {
    background-color: transparent !important;
}

.startMenuCloseButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: background-color 250ms, transform 250ms;
    font-style: normal !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    font-size: 35px !important;
}

.startMenuCloseButton:hover {
    background-color: transparent !important;
    transform: scale(1.1) !important;
}

.startMenu::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(245, 245, 245, 0.5);
}

.startMenu::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5);
    border-radius: 50px;
}

.startMenu::-webkit-scrollbar-thumb:hover {
    background-color: rgba(85, 85, 85, 0.5);
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.startMenuItem {
    background-color: rgba(255, 255, 255, 0.61) !important;
    transition: background-color 250ms, transform 250ms !important;
    font-size: 20px !important;
    color: #fff !important;
    background-color: transparent !important;
    opacity: 0;
    transform: translateY(10px);
    animation: slide-up 1s ease forwards;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.startCircular {
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    opacity: 0;
    animation: fade-in 1s ease forwards;
}

.startLogo {
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    cursor: default !important;
    transition: 250ms !important;
    color: #fff;
    opacity: 0;
    padding-left: 16px;
    padding-right: 16px;
    animation: fade-in 1s ease forwards;
}

.startMenuItem:hover {
    background-color: rgba(124, 95, 76, 0.438) !important;
    transform: scale(1.05) !important;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.toggleButtonFullscreen {
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition: background-color 250ms, transform 250ms;
    font-style: normal !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
    font-size: 20px !important;
    animation: slide-up 1s ease forwards;
}

.startPageWarningBox {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    background-color: rgb(204, 95, 95);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startPageWarningBox .startWarningInfoIcon {
    background-color: white;
    color: rgb(204, 95, 95);
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 20px;
}
.startWarningText {
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding: 10px;
}

@media (max-width: 844px) {
    .startMenu {
        position: relative !important;
        align-self: center !important;
        top: 0 !important;
        right: 0 !important;
        transition: 250ms !important;
    }
}

@media (max-width: 480px) {
    .startMenuItem {
        margin: 0 !important;
        padding: 0px !important;
        transition: 250ms !important;
    }
}

@media (max-height: 480px) {
    .startMenu {
        margin: 0 !important;
        padding: 0px !important;
        gap: 5px !important;
        transition: 250ms !important;
    }
    .startMenuItem {
        margin: 0 !important;
        padding: 0px !important;
        transition: 250ms !important;
        gap: 0px !important;
    }
}
