.chatItem {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid gray;
    padding: 8px;
    width: 100%;
    background-color: #fff;
    transition: background-color 300ms;
    box-sizing: border-box;
}
.chatItem:hover {
    background-color: #eee;
    cursor: pointer;
}

.chatAvatar {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    overflow: hidden;
    margin-right: 16px;
    border: 2px solid gray;
    background-size: cover;
}

.chatInfo {
    flex-grow: 1;
}

.chatInfo p {
    margin: 0;
}

.chatInfo h2 {
    margin: 0 0 8px 0;
    width: 100%;
}

.chatInfo h2 small {
    float: right;
    background-color: #009900;
    color: white;
    font-size: 15px;
    padding: 8px;
    border-radius: 20px;
}

.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.chatHeader {
    padding: 16px;
    border-bottom: 2px solid gray;
}

.chatBody {
    margin: 16px;
    overflow-y: scroll;
}

.chatBubble {
    background-color: #fff;
    border-radius: 16px;
    margin: 16px 56px 5px 16px;
    padding: 16px;
}

.chatBubble.mine {
    margin: 16px 16px 5px 56px;
    background-color: #dfae8e;
}

.chatBubble h3 {
    margin-top: 0;
}
