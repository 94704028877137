.location {
    transition: opacity 400ms;
    z-index: 2;
    cursor: pointer;
}

.island {
    cursor: pointer;
}

.location .label {
    font-size: 30px;
    color: white;
    text-shadow: 0px 0px 10px #000;
    position: absolute;
    left: 10px;
    top: -72px;
    cursor: pointer;
    transition: all 500ms;
}

.location .label.xl {
    font-size: 40px;
    transition: all 500ms;
}

.location .label.l {
    font-size: 30px;
    transition: all 500ms;
}

.location .label.m {
    font-size: 25px;
    transition: all 500ms;
}

.location .label.s {
    font-size: 10px;
    left: 5px;
    top: -60px;
    transition: all 500ms;
}

.location:hover .label {
    text-shadow: 0px 0px 10px #fff;
}

.location .placeCircle {
    fill: black;
    transition: fill 250ms;
}

.location:hover .placeCircle {
    fill: white;
}

.islandLabelContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.islandLabel {
    font-size: 30px;
    color: white;
    text-shadow: -2px 0px 0px rgb(0, 0, 0), 2px 0px 0px rgb(0, 0, 0), 0px -2px 0px rgb(0, 0, 0),
        0px 2px 0px rgb(0, 0, 0);
    transition: all 500ms;
    text-align: center;
    cursor: pointer;
}

.islandLabel:hover {
    text-shadow: 0px 0px 9px #fff;
}

.islandLabel.xl {
    font-size: 130px;
    transition: all 500ms;
}
.islandLabel.l {
    font-size: 70px;
    transition: all 500ms;
}
.islandLabel.m {
    font-size: 50px;
    transition: all 500ms;
}
.islandLabel.s {
    font-size: 30px;
    transition: all 500ms;
}
