.scene.board {
    background-size: cover;
}

.boardItem {
    position: absolute;
    width: 350px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.boardItem .content {
    padding: 24px;
}

.boardItem .title {
    margin-bottom: 0.5em;
    font-size: 2em;
}

.boardItem.core {
    background-color: #006185;
}

.boardItem.core.finished {
    background-color: #aaa;
}

.boardItem.core.finished:hover {
    background-color: rgb(122, 122, 122);
}

.boardItem.core:hover {
    background-color: #0076a1;
}

.boardItem.peripheral {
    background-color: #249e6b;
}

.boardItem.peripheral.finished {
    background-color: #aaa;
}

.boardItem.peripheral:hover {
    background-color: #61c49a;
}

.boardItem img {
    max-width: 100%;
}
