.scene {
    perspective: 1600px;
}

.scene.overlaid .inner {
    filter: blur(25px);
}

.scene .inner {
    transform-style: preserve-3d;
    transition: 1200ms transform;
}

.sceneClickarea {
    /* border: 1px solid #ffffff40; */
    border-radius: 24px;
    background-image: radial-gradient(at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%);
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    animation: PulseAnimation 2s ease-in-out infinite alternate;
}

.sceneClickarea.debug {
    animation: none;
    background: #ffffff40;
    border: 1px dashed red;
}

.sceneClickarea:hover {
    border: 1px solid #ffffff60;
    animation: none;
    background: #ffffff20;
}

@keyframes PulseAnimation {
    0% {
        background-size: 100% 100%;
    }
    100% {
        background-size: 10% 10%;
    }
}

.backlink {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: #990000;
    color: white;
    padding: 15px 40px;
    border-radius: 10px;
    cursor: pointer;
}

.backlink:hover {
    background-color: #bb0000;
}

.sceneItem {
    position: absolute;
    transition: transform 1200ms, opacity 400ms;
}

.sceneItem.medium {
    font-size: 1.5em;
}
.sceneItem.large {
    font-size: 2.5em;
}
.sceneItem.xlarge {
    font-size: 5.5em;
}

.sceneItem.whiteText {
    color: white;
}
.sceneItem.blur {
    filter: blur(16px);
}

.dialogWrapper {
    position: absolute;
}
.dialog {
    width: 100%;
    padding: 24px;
    background-color: #fffffff0;
    font-size: 2em;
    border-radius: 16px;
    border: 3px solid #00000088;
}

.answerPanel {
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: #ddd;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 20%;
    padding: 26px 16px 16px 16px;
    box-shadow: 0px 5px 30px #00000050;
}

.answerPanel .choice {
    padding: 24px;
    background-color: #990000;
    color: white;
    font-size: 1.5em;
    border-radius: 16px;
    margin: 0 10px 10px 10px;
    cursor: pointer;
}

.dialog .choice.next {
    display: inline-block;
}

.dialog .choice.completed:before {
    content: '✔ ';
}

.dialog .choice.disabled {
    background-color: #999;
    cursor: inherit;
}
.dialog .choice.disabled:hover {
    background-color: #999;
}

.dialog .choiceScene {
    padding: 24px;
    background-color: #dd6600;
    color: white;
    font-size: 1.5em;
    /* border-radius: 16px; */
    margin-bottom: 24px;
    margin-top: 10px;
    cursor: pointer;
}

.dialog .choice:hover {
    background-color: #bb0000;
}

.infoBox {
    background-color: white;
    position: absolute;
    margin: 24px;
    max-width: 360px;
    border-radius: 16px;
    overflow: hidden;
}
.infoBox img {
    max-width: 100%;
}

.infoBox .content {
    padding: 24px;
}

.infoBox .title {
    font-size: 1.6em;
    margin-bottom: 16px;
}

.infoBox .actions {
    text-align: right;
    margin-top: 16px;
}

.infoBox .actions button {
    border: none;
    background-color: #990000;
    padding: 12px 24px 8px 24px;
    color: white;
    cursor: pointer;
}

.infoBox .actions button:hover {
    background-color: #bb0000;
}

.infoBox .actions button:disabled {
    background-color: #bbb;
    cursor: default;
}

.contentBoard {
    position: absolute;
    width: 1920px;
    height: 1200px;
    transform-origin: top left;
}

.contentBoard .clickArea {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}

.overlay {
    z-index: 300;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    perspective: 1600px;
}

.overlay header {
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
